import { Component, forwardRef, Injector, Input } from "@angular/core"
import { NG_VALUE_ACCESSOR, NG_ASYNC_VALIDATORS, ControlContainer } from "@angular/forms"
import { FormBaseComponent } from "app/components/form-controls/form-base.component"
import { PopupService } from "app/services"
import { SharedModule } from "app/shared"
import _ from "lodash"

@Component({
  selector: "app-kv-edit",
  standalone: true,
  imports: [ SharedModule ],
  templateUrl: "./keyval-editor.component.html",
  styleUrls: ["./keyval-editor.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => KeyValEditorComponent)
    }
  ]
})
export class KeyValEditorComponent extends FormBaseComponent {

  key = ""
  val = ""

  @Input() keys: string[] = []
  @Input() kvs: {} = {}

  constructor(
    public ns: PopupService,
    protected override injector: Injector)
  {
    super(injector)
  }

  async deleteKey(key) {
    const resp = await this.ns.confirm("Confirm", `Deleting key ${key}. Are you sure?`)
    if (resp) {
      // this.kvs[key] = ""
      this._value[key] = null
      // delete this.kvs[key]
    }
  }

  addKey() {
    if (this.key && this.val) {
      this._value[this.key] = this.val
      this.key = null
      this.val = ""
    }
  }

  defined() {
    // const set = _.pick(this.kvs, v => (v !== "" && v !== null))
    return this._value
  }
}

/* eslint-disable @angular-eslint/no-output-native */
import { Component, EventEmitter, Input, Output } from "@angular/core"
import { PrimeModule } from "app/prime.module"

@Component({
  selector: 'app-plain-header',
  standalone: true,
  imports: [ PrimeModule],
  template: `
    <div class="plain-header">
      <i *ngIf="icon" class="text-primary-800 text-2xl pi pi-{{icon}} mr-3"></i>
      <div class="text-xl flex-auto">{{ title }}</div>
    </div>`,
  styles: [`
    .plain-header {
      z-index: 5;
      display: flex;
      align-items: center;
    }
  `]
})
export class PlainHeaderComponent {
  @Input() title
  @Input() icon
}

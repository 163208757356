import { Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output } from '@angular/core';
import { TableColumn } from 'app/shared/models'
import { FormBaseComponent } from "app/components/form-controls/form-base.component"
import { PopupService } from "app/services"
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms'
import { SharedModule } from 'app/shared'

@Component({
  selector: 'app-column-picker',
  standalone: true,
  imports: [ SharedModule ],
  template: `
    <p-multiSelect
      [options]="columns"
      [(ngModel)]="pickedCols"
      optionLabel="label"
      [showHeader]="false"
      selectedItemsLabel="{0} columns selected" class="mr-2"
      placeholder="Choose Columns"
      appendTo="body"
      (onChange)="colsChanged($event)">
    </p-multiSelect>

    <!-- <p-multiSelect
      [options]="columns"
      [(ngModel)]="pickedCols"
      optionLabel="label"
      [showHeader]="false"
      [displaySelectedLabel]="false"
      class="mr-2"
      dropdownIcon="pi pi-ellipsis-h"
      appendTo="body"
      (onChange)="colsChanged($event)">
    </p-multiSelect> -->
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ColumnPickerComponent)
    }
  ]
})
export class ColumnPickerComponent extends FormBaseComponent implements OnInit {

  @Input() columns: TableColumn[]
  @Output() changed = new EventEmitter<TableColumn[]>()
  pickedCols: any[]

  constructor(
    public ns: PopupService,
    protected override injector: Injector)
  {
    super(injector)
  }
  
  ngOnInit(): void {
    this.pickedCols = this.columns.filter(col => !col.hide)
  }

  colsChanged(e) {
    const col = this.columns.find(col => col == e.itemValue)
    if (col) {
      col.hide = !col.hide
      this.columns = [...this.columns]
    }
    this.changed.emit(this.columns)
  }
}

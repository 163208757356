
<p-table [columns]="columns" [value]="value ?? []"
  selectionMode="single" [(selection)]="selection"
  scrollHeight="flex" [scrollable]="true"
  [sortField]="sort" [sortOrder]="order">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <ng-container *ngFor="let col of columns">
        <th *ngIf="!col.hide" [pSortableColumn]="!col.nosort?col.field:null">
          {{col.label | uppercase}} <p-sortIcon *ngIf="!col.nosort" [field]="col.field"></p-sortIcon>
        </th>
      </ng-container>
    </tr>
  </ng-template>

  <!-- Column Type
    - shared    field, type, label, sort, hidden, click
    - url       url
    - tags      tags
    - badges    badges
    - icon      icon
    - date      format
    - render    (render)
    - text      -
  -->
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr [pSelectableRow]="rowData" (click)="onRowSelect($event, rowData)">
      <ng-container *ngFor="let col of columns">
        <td *ngIf="!col.hide" [class.wd-icon]="col.type==='icon'">
          <ng-container [ngSwitch]="col.type">

            <!-- url -->
            <a *ngSwitchCase="'url'"
              href='{{rowData.url}}' target="_blank"
              pTooltip="{{ rowData.url }}" tooltipPosition="bottom">{{ rowData.url ? "link" : "-"}}</a>

            <!-- tags -->
            <app-tag-list *ngSwitchCase="'tag'" [tags]="rowData[col.field]"></app-tag-list>

            <!-- icon -->
            <div *ngSwitchCase="'icon'">
              <ng-container *ngFor="let icon of col.icon.split(',')">
                <i *ngIf="icon.startsWith('pi-'); else google" class="pi {{icon}} wd-icon" (click)="col.click(icon, rowData)"></i>
                <ng-template #google>
                  <span class="material-icons-two-tone gray" (click)="col.click(icon, rowData)">
                    {{ icon }}
                  </span>
                </ng-template>
              </ng-container>
            </div>

            <!-- date -->
            <div *ngSwitchCase="'date'">
              {{ rowData[col.field] | date: col.format || "MM/dd/yyyy HH:mm"}}
            </div>

            <!-- default: property or render() -->
            <div *ngSwitchDefault>
              {{ col.render ? col.render(rowData) : rowData[col.field] }}
            </div>
          </ng-container>
        </td>
      </ng-container>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr class="w-full">
      <td [attr.colspan]="columns?.length" class="w-full text-center text-indigo-500 text-xl">No Data</td>
    </tr>
  </ng-template>

</p-table>

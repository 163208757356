import { Component } from "@angular/core"
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy"
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog"
import _ from "lodash"

import { CompanyDto, ItemDto } from "app/shared/dto"
import { Profile } from "app/shared/models"
import { CmsService, StoreService } from "app/services"
import { PopupService } from "app/services"
import { SharedModule } from "app/shared"
import { PopupHeaderComponent } from "../popup-header.component"
import { KeyValEditorComponent } from "../keyval-editor/keyval-editor.component"

// description
// url
// tags
// keyValues

@UntilDestroy()
@Component({
  selector: "item-dialog",
  standalone: true,
  imports: [ SharedModule, KeyValEditorComponent, PopupHeaderComponent ],
  templateUrl: "./item-dialog.component.html",
  styleUrls: ["./item-dialog.component.scss"]
})
export class ItemDialogComponent {
  company: CompanyDto = null
  profile: Profile
  model: ItemDto = null
  create = false
  tags: string[] = []

  constructor(
    public ref: DynamicDialogRef,
    private dlg: DynamicDialogConfig,
    public iproof: CmsService,
    public ns: PopupService,
    public store: StoreService
  ) {
    store.profile$.pipe(untilDestroyed(this)).subscribe(profile => {
      this.profile = _.cloneDeep(profile)
    })
    store.company$.pipe(untilDestroyed(this)).subscribe(company => this.company = company)
    const options = dlg.data;
    console.log(">>> " + JSON.stringify(options, null, 2))
    if (options.item) {
      this.model = _.cloneDeep(options.item);
    }
    this.create = (this.model == null)
  }

  submit() {
    // copy the new data
  }

  get parentTags(): string[] {
    if (this.model.tagDetails?.length == 3) {
      console.log("TAG DETAILS")
      return [...this.model.tagDetails[0], ...this.model.tagDetails[1]]
    }
    else
      return []
  }

  first(s: string[], ord: number) {
    let val = null;
    for (let i = 0; i <= ord; i++) {
      val = s[i] || val;
    }
    return val
  }
}

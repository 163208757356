import { Component, OnInit } from "@angular/core"
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms"
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy"
import _ from "lodash"

import { CompanyDto, Profile, SharedModule } from "app/shared"
import { CmsService, PopupService, StoreService } from "app/services"
import { environment } from "environments/environment"
import { PopupFooterComponent } from "../popup-footer.component"
import { PopupHeaderComponent } from "../popup-header.component"

class KeyVal {
  key: string
  val: any
}


@UntilDestroy()
@Component({
  selector: "app-profile-dialog",
  standalone: true,
  imports: [SharedModule, PopupFooterComponent, PopupHeaderComponent ],
  templateUrl: "./profile-dialog.component.html",
  styleUrls: ["./profile-dialog.component.scss"],
  host: { "class": "flex flex-column max-h-90" }
})
export class ProfileDialogComponent implements OnInit {
  public profile: Profile
  create = false
  roles: string[] = []
  companies: KeyVal[] = []
  access: number[] = []
  form: UntypedFormGroup
  error: string = null

  profileForm: UntypedFormGroup

  constructor(
    private fb: UntypedFormBuilder,
    public ns: PopupService,
    private cms: CmsService,
    public store: StoreService
  ) {
    this.create = false
  }

  async ngOnInit() {
    this.form = this.fb.group({
      email: [null, [Validators.required]],
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      companyId: [null, [Validators.required]],
      access: [null, [Validators.required]],
      role: [null, [Validators.required]],
      isProd: [null, [Validators.required]],
      isActive: [1, [Validators.required]]
    })
    this.form.valueChanges.subscribe(x => this.error = null)
    
    try {
      this.store.profile$.pipe(untilDestroyed(this)).subscribe(async profile => {
        this.profile = _.cloneDeep(profile) // it's a reference to the OG
        // retrieve a list of companies and roles
        const [companies, roles] = await Promise.all([this.cms.companies(), this.cms.listRoles()])
        this.companies = companies.filter(c => c.isActive).map(c => ({ key: c.companyName, val: +c.companyId }))
        this.roles = roles
        // TODO set some test members
        this.profile.companyAccess = "20,21,70,78"
        //
        this.access = this.profile.companyAccess.split(",").map(n => +n)
        this.form.patchValue({
          email: profile.email,
          firstName: profile.firstName,
          lastName: profile.lastName,
          companyId: profile.companyId,
          role: profile.role,
          isProd: this.store.isProd,
          access: this.access
        })
      })
    } catch (e) {
      console.log(e.toString())
    }
  }

  submit() {
    const values = this.form.value
    environment.production = values.isProd
    console.log("Updated", this.form.value)
    this.cms.refreshProfile(values.isProd)

    // this.store.company$.next(null)
    // this.store.profile$.next(this.profile)
  }
}

/*
member: {20: "Harlan Estate", 21: "BOND", 70: "The Mascot", 78: "Promontory", 110: "The Maiden"}
20: "Harlan Estate"
21: "BOND"
70: "The Mascot"
78: "Promontory"
110: "The Maiden"
members: "{\"20\":\"Harlan Estate\",\"21\":\"BOND\",\"70\":\"The Mascot\",\"78\":\"Promontory\",\"110\":\"The Maiden\"}"
*/

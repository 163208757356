import { Component, forwardRef, Injector, OnInit, ElementRef, ViewChild, Input, Renderer2 } from '@angular/core'
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ValidationErrors, Validator, AbstractControl, ControlContainer, AsyncValidator, NG_ASYNC_VALIDATORS } from '@angular/forms'
import { NGXLogger } from 'ngx-logger'

import { CmsService, StoreService } from 'app/services'
import { FormBaseComponent } from './form-base.component'
import { cleanCode, moveFocus } from "."
import { ItemInfoDto, ItemSummaryDto } from 'app/shared/dto'
import { LocationDto } from 'app/shared/storage.dto'
import { CmsException, IProofError } from 'app/shared/models'

// https://stackoverflow.com/questions/39242219/angular2-nested-template-driven-form/46748943#46748943
// https://mrpmorris.blogspot.com/2017/08/angular-composite-controls-formgroup-formgroupname-reactiveforms.html
// https://blog.profanis.me/blog/reactive-forms-in-parent-child-components
// https://blog.profanis.me/blog/smart-dumb-in-nested-reactive-forms

@Component({
  selector: "form-qr",
  styleUrls: ["./components.scss"],
  template: `
    <span class="p-input-icon-right w-full">
      <i [ngClass]="'pi pi-'+icon"></i>
      <input pInputText
        [id]="formControlName"
        type="text"
        [(ngModel)]="_value"
        [placeholder]="placeholder"
        #ctrl="ngModel"
        class="flex-auto w-full"
        [class.invalid]="self?.touched && !self?.valid && false"
        (focus)="dummy('focus')"
        (focusout)="dummy('focusout')"
        (keyup.enter)="enter(ctrl.value)"
        (keyup.tab)="dummy('tab')"
        (keyup)="onChange(ctrl.value)"
        (blur)="onTouched()">
    </span>`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormQrComponent)
    },
    {
      provide: NG_ASYNC_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormQrComponent)
    }
  ]
})
export class FormQrComponent extends FormBaseComponent implements AsyncValidator {

  @Input() qrType: "item" | "location" = "item"

  override _value = undefined
  override icon: string = "qrcode"
  item: ItemSummaryDto = null
  location: LocationDto = null
  prev: string = null           // prevent double checking on return
  err: any = null

  constructor(
    // to get a reference to the form control, use ControlContainer or Injector
    protected override injector: Injector,
    protected cms: CmsService,
    protected store: StoreService,
    protected log: NGXLogger,
    private render: Renderer2) {
    super(injector)
  }

  dummy(event: string) {
    this.log.trace(event)
  }

  async validate(control: AbstractControl): Promise<ValidationErrors | null> {
    const value = cleanCode(control.value)
    console.log(`formQR ${value}`)
    if (value && value != this.prev) {
      this.prev = value
      try {
        await this.validateQrType(value)
        this.log.debug(`${this.type} ${value} found`)
        // more validation
      } catch (e) {
        this.err = { qrType: { msg: e } }
      }
    }
    if (!this.err) {
      moveFocus(this.next)
    } else {
      // select text, add error style...
    }
    return this.err
  }

  // missing type
  // qrType: invalid type
  // error loading {{ type }}
  async validateQrType(code: string) {
    try {
      if (this.qrType == "item") {
        // test for item
      } else if (this.qrType == "location") {
        // test for location
      } else throw "Invalid type"
      this.err = null
    } catch (e) {
      throw e.error?.message || "Error fetching data"
    }
  }

  async assertType(type: string) {
    if (this.qrType != type) {
      throw "Invalid type"
    }
  }

  async enter(qr) {
    this.self.setValue(cleanCode(qr))
    this.self.markAsTouched()
  }
}

import { Component, OnInit } from "@angular/core"
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms"

import { NGXLogger } from "ngx-logger"

import { CmsService, StoreService } from "app/services"
import { PopupService } from "app/services"
import { Profile, SharedModule } from "app/shared/index"
import { PrimeModule } from "app/prime.module"
import { PopupHeaderComponent } from "../popup-header.component"
import { PopupFooterComponent } from "../popup-footer.component"

@Component({
  selector: "app-change-password",
  standalone: true,
  imports: [ PrimeModule, SharedModule, PopupHeaderComponent, PopupFooterComponent ],
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"]
})
export class ChangePasswordComponent implements OnInit {
  prod: boolean = false
  profile: Profile = undefined
  error: string = null
  form: UntypedFormGroup

  email: string = undefined
  code: string = undefined

  constructor(
    private cms: CmsService,
    private ns: PopupService,
    private store: StoreService,
    private fb: UntypedFormBuilder,
    private log: NGXLogger
  ) {
    this.log.trace("**change-password")
    this.store.prod$.subscribe(prod => this.prod = prod)
    this.store.profile$.subscribe(profile => this.profile = profile)
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      curPassword: [null, [Validators.required]],
      newPassword: [null, [Validators.required]]
    })
    this.form.valueChanges.subscribe(x => this.error = null)
  }

  async submit() {
    this.cms.changePassword({
      email: this.profile?.email,
      oldPassword: this.form.value.curPassword,
      password: this.form.value.newPassword,
      code: undefined
    }).then(() => {
      this.ns.tweet("Password changed successfully")
    }).catch(e => {
      this.ns.toast("ARGHHH")
      this.error = e.error.message || "unable to send the password reset email"
    })
  }

  fillForm() {
    this.form.patchValue({
      oldPassword: "iProof@1234",
      newPassword: "iProof@1234"
    })
  }
}

import { Component, Input, OnInit } from "@angular/core"
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy"
import dayjs from "dayjs"

import { StoreService } from "app/services"
import { SharedModule } from "app/shared"

@UntilDestroy()
@Component({
  selector: "app-changed-info",
  standalone: true,
  imports: [ SharedModule ],
  template: `
    <div class="my-1" class="text-blue-500">
      <span>{{ date }}</span>
      <span *ngIf="profile"> by <span class="text-tan-700">{{ profile.name }}</span></span>
    </div>`
})
export class ChangedInfoComponent implements OnInit {
  @Input() model

  profile = null

  constructor(private store: StoreService) {
    this.store.profile$.pipe(untilDestroyed(this)).subscribe(profile => {
      this.profile = profile
    })
  }

  ngOnInit(): void {
    const users = []
  }

  get date() {
    const dt = dayjs(this.model.updatedOn)
    const date = dt.format("YYYY/MM/DD")
    const time = dt.format("HH:mm")
    if (!this.model?.updatedOn) {
      return "No updated date"
    }
    return `Last updated on ${date} at ${time}`
  }
}

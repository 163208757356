import { AfterViewInit, Component, Injector, Input } from '@angular/core'
import { AbstractControl, ControlContainer, ControlValueAccessor, FormControl, NgControl } from '@angular/forms'

// https://blog.angular-university.io/angular-custom-form-controls/
// https://medium.com/@vinothinikings/control-value-accessor-in-angular-dfc338ea0f18
// https://stackoverflow.com/questions/53185211/angular-7-component-inheritance-using-a-global-injector
// https://levelup.gitconnected.com/angular-get-control-in-controlvalueaccessor-b7f09a485fba
// https://stackoverflow.com/questions/44731894/get-access-to-formcontrol-from-the-custom-form-component-in-angular

@Component({
  selector: "value-base",
  template: ""
})
export class FormBaseComponent implements ControlValueAccessor, AfterViewInit {
  onChange: (value: any) => void
  onTouched: () => void

  _value = undefined

  @Input() type = "text"
  @Input() name
  @Input() label
  @Input() prompt
  @Input() icon
  @Input() next
  @Input() accept
  @Input() disabled = false
  self: AbstractControl


  @Input() focus = undefined
  @Input() placeholder
  @Input() default = undefined

  @Input() formControlName

  constructor(protected injector: Injector = null) {
  }

  ngAfterViewInit(): void {
    this.self = this.injector.get(NgControl, null)?.control
    // this.self?.setValue("Gotcha")
    // this.self = this.container.control?.get(this.formControlName)
  }

  // value set by the parent
  writeValue = (value: any) => {
    this._value = value
  }

  registerOnChange = (fn: (_: any) => void) => this.onChange = fn

  registerOnTouched = (fn: any): void => this.onTouched = fn

  makeLabel(label) {
    return "The " + label.replace(/([0-9A-Z])/g, " $&").toLowerCase()
  }
}

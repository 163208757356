import { Component, HostBinding, Input } from "@angular/core"
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy"
import { DynamicDialogRef } from "primeng/dynamicdialog"
import _ from "lodash"

import { CmsService, PopupService, StoreService } from "app/services"
import { CompanyDto } from "app/shared/dto"
import { Profile } from "app/shared/models"
import { SharedModule } from "primeng/api"
import { PopupFooterComponent } from "./popup-footer.component"
import { PopupHeaderComponent } from "./popup-header.component"
import { PrimeModule } from "app/prime.module"

@UntilDestroy()
@Component({
  selector: "app-switch-org",
  standalone: true,
  imports: [PrimeModule, SharedModule, PopupFooterComponent, PopupHeaderComponent],
  template: `
    <app-popup-header icon="sort-alt" title="Switch Company"></app-popup-header>

    <div class="flex-content">
        <p-listbox #company
            [options]="companies"
            [(ngModel)]="newCompany"
            styleClass="border-none"
            optionLabel="companyName"
            optionValue="companyId"
            (onDblClick)="submit()">
        </p-listbox>
    </div>

    <app-popup-footer acceptText="SWITCH" (reject)="ns.close()" (accept)="submit()"></app-popup-footer>
  `
})
export class SwitchDialogComponent {
  @HostBinding("class") hostClass = "flex flex-column max-h-90"

  @Input() show: boolean = false
  profile: Profile
  companies: CompanyDto[] = []
  currentCompany: number = 0
  memberds
  newCompany: number = null

  constructor(
    public ns: PopupService,
    private iproof: CmsService,
    public ref: DynamicDialogRef,
    public store: StoreService
  ) {
    store.profile$.pipe(untilDestroyed(this)).subscribe(async profile => {
      if (!_.isEmpty(profile?.email)) {
        this.companies = await this.iproof.companies()
        this.companies = this.companies.filter(c => c.isActive)
        this.currentCompany = profile.companyId
        // TODO set some test members
        profile.companyAccess = "20,21,70,78,43,48,60,50"
        // TODO
        if (!profile.isRoot) {
          const access = (profile.companyAccess?.split(",") || []).map(n => +n)
          this.companies = this.companies.filter(c => access.indexOf(+c.companyId) >= 0)
        }
        this.newCompany = this.companies.find(c => c.companyId == profile.companyId).companyId
      }
    })
  }

  async submit() {
    try {
      if (this.newCompany && this.currentCompany != this.newCompany) {
        await this.iproof.changeCompany(this.newCompany)
        const company = this.companies.find(c => c.companyId == this.newCompany)
        this.ns.toast("Company Switched")
        await this.ns.tweet(`Your new company is now ${company.companyName} (${company.companyId})`)
      }
    } catch (e) {
      await this.ns.alert("Error", `Unable to switch company`)
    } finally {
      this.ns.close()
    }
  }
}

import { Component, EventEmitter, OnInit, Input, Output, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms'
import { PrimeModule } from 'app/prime.module'
import { SharedModule } from 'app/shared'
import { NGXLogger } from 'ngx-logger'
import { InputText } from 'primeng/inputtext'

@Component({
  selector: 'input-scan',
  standalone: true,
  imports: [PrimeModule, SharedModule],
  template: `
    <span class="p-input-icon-right w-full" (focus)="onFocus()" [id]="id">
      <i [ngClass]="['pi pi-'+icon, color]"></i>
      <input #elm pInputText class="w-full" [required]="required && status !== 1"
        id="elm" [formControl]="bc" (focusout)="focusOut()" (keyup.enter)="return(elm.value)">
      </span>
      <small *ngIf="error" class="p-error">{{ error }}</small>
      <val-errors [control]="bc" label="Location"></val-errors>`,
  styles: [``]
})
export class InputScanComponent implements OnInit, OnChanges {

  @Input() status: number = 0
  @Output() enter = new EventEmitter()
  @Input() id: string
  @Input() required: boolean = false
  @Input() error: string = null

  value: string = ""
  icon: string = "qrcode"
  color: string = ""

  bc: FormControl = new FormControl()

  constructor(
    public log: NGXLogger
  ) { }

  ngOnInit(): void {
    this.log.info("input-scan activated for " + this.id)
  }

  // respond to the location changes from the parent view
  ngOnChanges(changes: SimpleChanges) {
    if (this.status && changes?.status) {
      this.setStatus(this.status)
    }
    if (this.error && changes?.error) {
      if(!this.error) this.setStatus(0)
    }
  }

  onFocus(): void {
    // document.getElementById("elm")?.focus()
    console.log('Focus Is gained for this Element')
  }

  setStatus(status: number) {
    this.log.info("Status changed to", status)
    this.status = status
    if (status == 0) {
      this.icon = "qrcode"
      this.color = ""
    } if (status == 1) {
      this.icon = "check"
      this.color = "text-green-500"
    } else if (status == 2) {
      this.icon = "ban"
      this.color = "text-red-700"
      const elm = document.getElementById("elm") as HTMLInputElement
      elm.select()
    }
  }

  return(text) {
    this.log.info("Cart input:", text)
    // const elm = document.getElementById("elm") as HTMLInputElement
    // elm.select()
    if (this.bc.valid) this.enter.emit(text)
  }

  focusOut() {
    console.log("LOST FOCUS")
    if (this.status == 2) this.setStatus(0)
    this.error = null
  }
}

/*
- when qr code needed set icon to qr
- upon enter, callback for validation when field is not empty, setting status:
  - 0   need bc     icon:qr
  - 1   valid id    icon:check
  - 2   invalid id  icon:error, select text
*/

<app-popup-header icon="lock" title="Change Password"></app-popup-header>

<div class="content p-4">
  <form [formGroup]="form">

    <span *ngIf="error" class="text-red-700">{{ error }}</span>

    <!-- current password -->
    <div class="field" [class.mt-3]="error">
      <label for="p1">Current Password</label>
      <p-password id="p2" formControlName="curPassword" [feedback]="false" [toggleMask]="true"
        [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"></p-password>
      <val-errors controlName="curPassword" label="Your current password"></val-errors>
    </div>

    <!-- new password -->
    <div class="field">
      <label for="p2">New Password</label>
      <p-password id="p2" formControlName="newPassword" [feedback]="false" [toggleMask]="true"
        [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"></p-password>
      <val-errors controlName="newPassword" label="Your new password"></val-errors>
    </div>

  </form>

</div>

<app-popup-footer [valid]="form.valid" (accept)="submit()"></app-popup-footer>

import { Component, ElementRef, AfterViewInit, Input, ViewChild } from '@angular/core'
import { PrimeModule } from 'app/prime.module'

@Component({
  selector: 'app-code',
  standalone: true,
  imports: [PrimeModule],
  styles: [`
    pre[class*="language-"] {
      &:before,
      &:after {
        display: none !important;
      }
      code {
        border-left: 6px solid var(--surface-border) !important;
        box-shadow: none !important;
        background: var(--surface-ground) !important;
        margin: 1em 0;
        color: var(--text-color);
        font-size: 14px;
      }
    }
    :host ::ng-deep pre[class*="language-"] {
      margin: 0 !important;
      &:before,
      &:after {
        display: none !important;
      }
      code {
        border-left: 0 none !important;
        box-shadow: none !important;
        background: var(--surface-e) !important;
        margin: 0;
        color: var(--text-color);
        font-size: 14px;
        padding: 0 2rem !important;
      }
    }
    i:not([class~="pi"]) {
      background-color: transparent;
      color: #2196f3;
      font-family: Monaco, courier, monospace;
      font-style: normal;
      font-size: 12px;
      font-weight: 500;
      padding: 0 4px;
      letter-spacing: 0.5px;
      font-weight: 600;
      margin: 0 2px;
      display: inline-flex;
    }
  `],
  template: `
<pre [ngClass]="'language-' + lang"><code #code><ng-content></ng-content>
</code></pre>`
})
export class AppCodeComponent implements AfterViewInit {
  @Input() lang = 'markup'
  @ViewChild('code') codeViewChild: ElementRef

  constructor(public el: ElementRef) { }

  ngAfterViewInit() {
    if (window['Prism']) {
      window['Prism'].highlightElement(this.codeViewChild.nativeElement)
    }
  }
}

<app-popup-header icon="user" [title]="create ? 'Create Unit' : 'Update Unit'"></app-popup-header>

<!-- SKU & Lot details --
<div class="-mx-4 px-4 py-2 mb-4 sku-info">
  <div class='flex flex-column'>
    <div class='flex m-1'>
      <div class='w-8rem text-primary-700'>SKU</div>
      <div>{{1234 || '-'}}</div>
    </div>
    <div class='flex m-1'>
      <div class='w-8rem text-primary-700'>Description</div>
      <div class="flex flex-column">
        <div>{{model?.description[0] || '-'}}</div>
        <div *ngIf="model?.description[1]">{{model?.description[1]}}</div>
      </div>
    </div>
    <div class='flex m-1'>
      <div class='w-8rem text-primary-700'>URL</div>
      <div class="flex-auto">{{model?.url[1] || model?.url[0] || '-'}}</div>
    </div>
    <div class='flex m-1'>
      <div class='w-8rem text-primary-700'>TAGS</div>
      <tags [tags]="tags"></tags>
    </div>
  </div>
</div> -->

<!-- sku & lot information
    <div class="field">
      <div class='flex flex-column -mx-6 px-6 py-2 sku-info'>
        <div class='flex m-1'>
          <div class='w-12rem text-primary-700'>SKU</div>
          <div class="flex flex-column">
            <div>{{model.description[0] || '-'}}</div>
            <div *ngIf="model.description[1]">{{model.description[1]}}</div>
          </div>
        </div>
        <div class='flex m-1'>
          <div class='w-12rem text-primary-700'>URL</div>
          <div class="flex-auto">{{model.url[1] || model.url[0] || '-'}}</div>
        </div>
        <div class='flex m-1'>
          <div class='w-12rem text-primary-700'>TAGS</div>
          <div class="flex">
            <div *ngFor="let tag of parentTags" class='inline-tag ng-value-label'>{{tag}}</div>
          </div>
        </div>
      </div>
    </div> -->
<!-- SKU description -->
<div class="field">
  <label>Description</label>
  <input pInputText [(ngModel)]="model?.description[2]" pTooltip="Unit specific description" tooltipPosition="bottom" class="inputfield w-full">
</div>

<!-- url -->
<div class="field">
  <label>URL</label>
  <input pInputText [(ngModel)]="model?.url[2]" pTooltip="Unit specific url" tooltipPosition="bottom" class="inputfield w-full">
</div>

<!-- tags -->
<div class="field">
  <label>Tags</label>
  <p-multiSelect
    [options]="company.badges"
    placeholder="Additional unit tags"
    optionLabel="tag"
    display="chip"
    [showHeader]="false"
    [(ngModel)]="model.tagDetails[2]"
    styleClass="inputfield w-full"
    appendTo="body"></p-multiSelect>
</div>

<!-- key-values -->
<div class="field pt-3">
  <app-kv-edit [keys]="company.allKeys" [kvs]="model?.keyValues" class="w-full"></app-kv-edit>
</div>

<div class="flex justify-content-end align-items-center">
  <button pButton pRipple type="button" class="p-button-text mr-2" label="Cancel" (click)="ns.close()"></button>
  <p-button label="SUBMIT" (click)="submit()"></p-button>
</div>

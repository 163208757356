import { Directive } from '@angular/core'
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms'
import { cleanCode } from './utilities'

@Directive({
  selector: "[iProofId]",
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: IProofIdDirective,
    multi: true
  }]
})
export class IProofIdDirective implements Validator {

  validate(control: AbstractControl): ValidationErrors | null {
    return this.iProofIdValidator()(control)
  }

  iProofIdValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let value = cleanCode(control.value)
      if (!value) return null
      console.log("~iproof", value)
      value = value.trim().replace(/-/g, "")
      const valid = value.length == 8 || value.length == 10
      return valid ? null : { iproofId: true }
    }
  }
}

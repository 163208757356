<app-popup-header icon="user" [title]="create ? 'Create Account' : 'Update Account'"></app-popup-header>

<div class="flex-content p-4">

  <form [formGroup]="form">

    <!-- email -->
    <div class="field">
      <label>Email</label>
      <input pInputText formControlName="email" class="w-full">
      <val-errors controlName="email" label="Your email"></val-errors>
    </div>

    <!-- full name -->
    <div class="formgrid grid">
      <div class="field col">
        <label>First name</label>
        <input pInputText formControlName="firstName" class="w-full">
        <val-errors controlName="firstName" label="Your first name"></val-errors>
      </div>
      <div class="field col">
        <label>Password</label>
        <input pInputText formControlName="lastName" class="w-full">
        <val-errors controlName="lastName" label="Your last name"></val-errors>
      </div>
    </div>

    <!-- company -->
    <div class="field">
      <label for="company">Company</label>
      <p-dropdown
        inputId="company"
        [options]="companies"
        formControlName="companyId"
        optionLabel="key"
        optionValue="val"
        styleClass="w-full"
        appendTo="body"
        [autoDisplayFirst]="false">
      </p-dropdown>
    </div>

    <!-- companies -->
    <div class="field">
      <label for="companies">Companies</label>
      <p-multiSelect
        inputId="companies"
        [options]="companies"
        optionLabel="key"
        optionValue="val"
        formControlName="access"
        [showHeader]="false"
        styleClass="w-full"
        appendTo="body"></p-multiSelect>
    </div>

  </form>
</div>

<app-popup-footer [valid]="form.valid" (accept)="submit()"></app-popup-footer>

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core"

import { TableColumn } from "app/shared"
import { SharedModule } from "primeng/api"
import { TagListComponent } from "../tag-list.component"
import { PrimeModule } from "app/prime.module"

@Component({
  selector: "tbl",
  standalone: true,
  imports: [ PrimeModule, SharedModule, TagListComponent ],
  templateUrl: "./tbl.component.html",
  styleUrls: ["./tbl.component.scss"]
})
export class TblComponent implements OnInit, OnChanges {
  @Input() value = []
  @Input() columns: TableColumn[] = []
  @Input() sort: string
  @Output() rowClick = new EventEmitter<any>()

  order: number = 1
  selection

  constructor() {
  }

  // respond to the location changes from the parent view
  ngOnChanges(changes: SimpleChanges) {
    if (changes?.columns && !changes.columns.firstChange) {
      this.colsChanged()
    }
  }

  ngOnInit() {
    this.colsChanged()
  }

  colsChanged() {
    this.columns = this.columns
    this.columns.forEach(col => {
      if (col.icon) col.nosort = false
      if (!col.label) col.label = col.field.replace(/([0-9A-Z])/g, " $&")
    })
    if (this.sort?.charAt(0) == '-') {
      this.sort = this.sort.slice(1)
      this.order = -1
    }
  }

  onRowSelect(event, row) {
    event.stopPropagation()
    console.log(`Row selected: ${row.externalId}`)
    this.rowClick.emit(row)
  }
}

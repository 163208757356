import { Component, Input, Output, EventEmitter } from '@angular/core'
import { SharedModule } from 'app/shared'

@Component({
  selector: 'image-map',
  standalone: true,
  imports: [SharedModule],
  template: `
    <div id="image-container">
      <img [src]="src">
      <div class="coordinates-container">
        <div *ngFor="let coordinate of coordinates" class="area" [title]="coordinate.name" [style]="getCoordinateStyle(coordinate)"
          (click)="onAreaClick(coordinate)"
          (contextmenu)="onAreaEdit(coordinate)">
        </div>
      </div>
    </div>
  `,
  styles: [`
    .coordinates-container {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;

      .area {
        position: absolute;
        cursor: pointer;
        transition: all 0.1s;
        &:hover{
          border: 2px solid lightgray;
          box-shadow: 0 0px 5px darkgray;
        }
      }
    }

    #image-container {
      position: relative;
    }
  `]
})
export class ImageMapComponent {

  @Input()
  src: string

  @Input()
  coordinates: ImageMapCoordinate[]

  @Input()
  canEdit: boolean

  @Output() clicked: EventEmitter<ImageMapCoordinate> = new EventEmitter();

  constructor() { }

  getCoordinateStyle(coordinate: ImageMapCoordinate): object {
    return {
      top: `${coordinate.y}px`,
      left: `${coordinate.x}px`,
      height: `${coordinate.height}px`,
      width: `${coordinate.width}px`
    }
  }

  onAreaClick(coordinate) {
    this.clicked.emit(coordinate)
  }

  onAreaContext(e: MouseEvent, coordinate: ImageMapCoordinate) {
    if (this.canEdit) {
      if (coordinate)
        console.log('editing')
      else {
        console.log('creating')
      }
    }
    e.stopPropagation()
    return false
  }

  onAreaCreate(x: number, y: number): ImageMapCoordinate {
    const coordinate = new ImageMapCoordinate({ x, y, width: 100, height: 100 })
    return coordinate
  }

  onAreaEdit(coordinate: ImageMapCoordinate): ImageMapCoordinate {
    return coordinate
  }

}

export class ImageMapCoordinate {
  x: number = 0
  y: number = 0
  width: number = 100
  height: number = 100
  name?: string

  constructor(some?: Partial<ImageMapCoordinate>) {
    Object.assign(this, some)
  }
}

import { Component, Input } from "@angular/core"
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy"
import { NGXLogger } from "ngx-logger"
import _ from "lodash"

import { CmsService, StoreService } from "app/services"
import { Badge, CompanyDto, SharedModule } from "app/shared"
import { PopupFooterComponent } from "./popup-footer.component"

@UntilDestroy()
@Component({
  selector: "tag-panel",
  standalone: true,
  imports: [SharedModule, PopupFooterComponent],
  template: `
    <div class='h-full flex flex-column m-0'>

      <div class="p-1 m-0 flex-auto overflow-y-auto surface-50">
        <div *ngIf="selectedBadges.length" class="pb-4">
          <div *ngFor='let badge of selectedBadges'
            (click)='removeTag(badge)'
            class='flex align-content-center vtag shadow-3 mb-2'
            [style.backgroundColor]='badge.bg'>
            <span>{{ badge.tag }}</span>
            <span class="flex flex-auto"></span>
            <span class="material-icons close">close</span>
          </div>
      </div>

      <div *ngFor='let badge of searchBadges'
        (click)='selectTag(badge)'
        class='vtag shadow-3 mb-2'
        [style.backgroundColor]='badge.bg'>
        {{ badge.tag }}
      </div>
    </div>

  </div>
`,
  styles: [`
    .tag {
      cursor: pointer;
      border-radius: 4px;
      text-align: center;
      color: var(--gray-900);
      padding: 4px 8px;
      font-size: 0.95em;
      opacity: 0.7;
      min-width: 100px;
      .close {
        font-size: 1.4em;
      }
    }

    .vtag {
      @extend .tag;
      transition: opacity 150ms linear;
      font-weight: normal;
      &:hover {
        opacity: 1;
        font-weight: 600;
      }
    }

    .no-selection {
        font-size: 0.8em;
        text-align: center;
        text-transform: capitalize;
        color: purple;
    }
  `]
})
export class TagPanelComponent {
  @Input() exclusive: boolean = true

  company: CompanyDto
  tags: string[]
  searchBadges: Badge[] = []      // tags available to be added to search
  selectedBadges: Badge[] = []    // tags active in the search

  constructor(
    private iproof: CmsService,
    private store: StoreService,
    private log: NGXLogger
  ) {
    this.log.trace("**tagPanel")
    store.company$.pipe(untilDestroyed(this)).subscribe(company => {
      if (company && company.companyId != this.company?.companyId) {
        this.log.debug(`tag-panel: company ${company.companyId}`)
        this.company = company
        this.updateBadges()
      }
    })
    store.tags$.pipe(untilDestroyed(this)).subscribe(tags => {
      this.selectedBadges = this.tagsToBadges(tags)
      this.updateBadges()
    })
  }

  tagsToBadges(tags: string[]) {
    return tags?.map(tag => this.company?.badges.find(badge => badge.tag == tag)) || []
  }

  selectTag(badge: Badge) {
    this.selectedBadges.push(badge)
    this.store.tags$.next(this.selectedBadges.map(badge => badge.tag))
    this.updateBadges()
  }

  removeTag(badge: Badge) {
    _.remove(this.selectedBadges, badge)
    this.store.tags$.next(this.selectedBadges.map(badge => badge.tag))
    this.updateBadges()
  }

  updateBadges() {
    if (this.exclusive) {
      const sets = _.uniq(this.selectedBadges.map(badge => badge.group))
      this.searchBadges = this.company?.badges.filter(badge => !sets.includes(badge.group))
    } else {
      this.searchBadges = this.company?.badges.filter(badge => !this.selectedBadges.includes(badge))
    }
  }
}

import dayjs from "dayjs"

export function sleepUntil(condition, timeoutMs = 2500, pollMs = 50) {
  return new Promise((resolve, reject) => {
    const t0 = +dayjs()
    const wait = setInterval(() => {
      if (condition()) {
        console.log(`Resolved after ${+dayjs() - t0} ms`)
        clearInterval(wait)
        resolve(true)
      } else if (+dayjs() - t0 > timeoutMs) {
        clearInterval(wait)
        reject()
      }
    }, pollMs)
  })
}

export function cleanCode(code: string) {
  const qr = code?.trim().split("/").pop()
  return qr
}

export function addOne(position: string): string {
  let coord: string = ""
  if (position) {
    const [row, col] = position.split(",").map(n => +n + 1)
    coord = `R${row} P${col}`
  }
  return coord
}

export function moveFocus(id: string) {
  const elm = document.getElementById(id)
  elm?.focus()
}

export function selectText(id: string) {
  const elm = document.getElementById(id) as HTMLInputElement
  elm?.select()
}


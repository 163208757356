import { Component, Input } from "@angular/core"
import { PrimeModule } from "app/prime.module"

import { Badge } from "app/shared/models"

@Component({
  selector: "app-tag-list",
  standalone: true,
  imports: [ PrimeModule],
  template: `
    <div *ngIf="badges; else textTags" class="wrapper flex flex-wrap">
      <div *ngFor="let badge of badges" class="vtag shadow-1" [style.backgroundColor]="badge.bg">
        {{ badge.tag }}
      </div>
    </div>
    <ng-template #textTags>
      <div *ngIf="tags" class="wrapper flex flex-wrap">
        <div *ngFor="let tag of tags" class="tag shadow-1">
          {{ tag }}
        </div>
      </div>
    </ng-template>`,
  styles: [`
    .tag {
        cursor: pointer;
        // height: 22px;
        border-radius: 4px;
        line-height: 22px;
        text-align: center;
        padding: 0 4px;
        background-color: rgb(208, 221, 229);
        color: black;
        opacity: 0.7;
        mat-icon {
            font-size: 14px;
            line-height: 24px;
            color: #449;
            width: 16px;
        }
    }
    .vtag {
        @extend .tag;
        transition: opacity 150ms linear;
        font-weight: normal;
        &:hover {
            opacity: 1;
            transition: opacity 150ms linear;
            font-weight: 600;
        }
    }
    .wrapper {
        margin: -6px;
    }
    .wrapper > * {
        margin: 2px;
    }
  `]
})
export class TagListComponent {
  @Input() badges: Badge[] = null
  @Input() tags: string[] = []

  constructor() {
  }
}

import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { PrimeModule } from 'app/prime.module'
import { CmsService, StoreService, ThemeService } from 'app/services'
import { KeyVal, Profile } from 'app/shared'
import { environment } from 'environments/environment'
import _ from "lodash"

@UntilDestroy()
@Component({
  selector: 'app-slide-panel',
  standalone: true,
  imports: [PrimeModule],
  template: `
    <p-sidebar
      [(visible)]="themeService._settingsActive"
      position="right"
      styleClass="w-full sm:w-18rem"
      transitionOptions=".3s">

      <ng-template pTemplate="header">
        <span class="text-xl">SETTINGS</span>
      </ng-template>

      <h5>Color Scheme</h5>
      <div class="flex-row gap-4">
        <p-radioButton name="theme" [(ngModel)]="themeService.style" value="dark" label="Dark"></p-radioButton>
        <p-radioButton name="theme" [(ngModel)]="themeService.style" value="light" label="Light"></p-radioButton>
      </div>

      <ng-container *ngIf="isLocal">

        <h5>Execution Mode</h5>
        <div class="flex-row gap-4">
          <p-radioButton name="mode" [(ngModel)]="store.prod" [value]="false" label="Dev"></p-radioButton>
          <p-radioButton name="mode" [(ngModel)]="store.prod" [value]="true" label="Prod"></p-radioButton>
        </div>

        <h5>Role</h5>
        <p-dropdown
          inputId="role"
          [options]="roles"
          [(ngModel)]="role"
          styleClass="w-full"
          appendTo="body"
          [autoDisplayFirst]="false">
        </p-dropdown>

      </ng-container>
    </p-sidebar>
  `
})
export class SlidingPanelComponent implements OnInit {

  constructor(
    public themeService: ThemeService,
    protected cms: CmsService,
    protected store: StoreService,
    protected router: Router
  ) {
  }

  env = environment

  prod: boolean
  roles: string[] = []

  profile: Profile = null
  companies: KeyVal<number>[] = []
  access: number[] = []

  ngOnInit() {
    try {
      // catch a change in mode
      this.store.prod$.pipe(untilDestroyed(this)).subscribe(prod => this.prod = prod)

      // listen to profile changes
      this.store.profile$.pipe(untilDestroyed(this)).subscribe(async profile => {
        this.profile = _.cloneDeep(profile) // it's a reference to the OG
        // retrieve a list of companies and roles
        const [companies, roles] = await Promise.all([this.cms.companies(), this.cms.listRoles()])
        this.companies = companies.filter(c => c.isActive).map(c => ({ key: c.companyName, value: +c.companyId }))
        this.roles = roles
        //! set some test members
        this.profile.companyAccess = "20,21,70,78"
        //
        this.access = this.profile.companyAccess.split(",").map(n => +n)
      })
    } catch (e) {
      console.log(e.toString())
    }
  }

  get isLocal(): boolean { return window.location.href.includes("localhost") }

  get role(): string { return this.profile?.role }

  set role(_role: string) { this.profile.role = _role }
}

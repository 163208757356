import { NgModule } from "@angular/core"

import { SharedModule } from "app/shared/shared.module"
import { FormBarcodeComponent } from "."
import { IProofIdDirective } from "./iProofIdDirective"

const Components = [
  FormBarcodeComponent,
  IProofIdDirective,
]

@NgModule({
  declarations: [...Components],
  imports: [ SharedModule ],
  exports: [...Components]
})
export class FormControlsModule { }

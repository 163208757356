 <table class="w-full">
  <!-- existing key-value pairs-->
  <tr>
    <th class="p-1">Key</th>
    <th class="p-1">Value</th>
  </tr>
  <ng-container *ngFor="let kv of _value | keyvalue">
    <tr *ngIf="kv.value !== '' && kv.value !== undefined">
      <td class="p-1">{{kv.key}}</td>
      <td>
        <div class="flex justify-content-between align-items-center">
          <div class="flex-auto p-1">{{kv.value}}</div>
          <i class="pi pi-times p-1" (click)="deleteKey(kv.key)"></i>
        </div>
      </td>
    </tr>
  </ng-container>

  <tr>
    <td>
      <!-- <p-dropdown [options]="keys" [(ngModel)]="key" appendTo="body"
          styleClass="dd"></p-dropdown> -->
      <select class="w-full p-1" [(ngModel)]='key'>
        <option *ngFor="let key of keys" [value]="key">{{key}}</option>
      </select>
    </td>
    <td>
      <div class="flex justify-content-between align-items-center">
        <input (keyup.enter)="addKey()" [(ngModel)]='val' class="flex-auto inputfield pr-3 outline-none p-1" />
        <i class="pi pi-plus p-1 text-primary-300" (click)="addKey()"></i>
      </div>
    </td>
  </tr>
</table>
